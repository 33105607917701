import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { PackHistory } from './components/PackHistory';
import { WorkstationRoutes } from './components/Workstations/WorkstationRoutes';
import './custom.css';
import { PrintQueue } from './components/PrinterPresets/PrintQueue';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { processUrl } from './store/reducer';
import { VersionNumber } from './components/VersionNumber';

import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

const SentryRoute = Sentry.withSentryRouting(Route);

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#375954',
    },
    secondary: {
      main: '#d3d3d3',
    },
    error: {
      main: '#d35f5f',
    },
    warning: {
      main: '#e9822e',
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    store.dispatch(() => processUrl(false));
  }, []);
  return (
    <AppStyle>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={materialOverrides}>
            <VersionNumber />
            <PersistGate loading={null} persistor={persistor}>
              <Switch>
                <SentryRoute exact path="/" component={Home} />
                <SentryRoute exact path="/history" component={PackHistory} />
                <Layout>
                  <SentryRoute
                    path="/workstation"
                    component={WorkstationRoutes}
                  />
                </Layout>
              </Switch>
            </PersistGate>

            <PrintQueue />
          </MuiThemeProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </AppStyle>
  );
};

export default App;

window.onpopstate = () => {
  store.dispatch(() => processUrl(false));
};

const AppStyle = styled.div`
  font-family: 'Roboto';
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #375954;
  touch-action: pan-y;
`;
